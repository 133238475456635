// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
.Datepicker--DateList {
  display: flex;
  margin: 2px 0 2px 0;
}

.Datepicker--DateDayItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  // padding-right: 2em;
  // padding-bottom: 4px;
  margin-right: 45px;
}

.Datepicker--Strip {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  width: max-content;
  max-width: 100%;
  overflow: hidden;
  font-size: 12px;
}

.DateDayItem--selected {
  font-weight: 700;
  height: 45px;
  border-bottom: 4px solid #03A9F3;
  color: black !important;
}

.DateDayItem--disabled {
  color:black;
  font-weight: bold;
}

.Datepicker--DateList--scrollable {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
  padding: 0px 0px 0px 15px;
  // margin: 2px 0 2px 0;
  // border-bottom: 1px solid;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }
}

.Datepicker {
  display: flex;
  width: 100%;
  align-items: center;
  color: #102E35;
  font-family:'Poppins'!important;
  // margin-top: 12px;
}

.Datepicker--DayLabel {
  color: #102E35;
  font-family:'Poppins'!important;
  // font-size: 12px;
  // margin-top: 5px;
}

.Datepicker--button-prev,
.Datepicker--button-next {
  border: none;
  text-decoration: none;
  background: rgb(77, 123, 243);
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;

 // font-size: 14px;
  font-weight: bold;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Datepicker--MonthYearLabel {
  // margin-left: 4px;
  align-self: left;
  margin-left: 2%;
  width: 6em;
  text-align: left;
  color: #102E35;
  font-family:'Poppins'!important;
}

.Datepicker--Container {
  max-width: 100%;
  display: flex;
  width: 650px;
  border-bottom: 1px solid #DEDEDE;
  .Datepicker {
    .MuiButtonBase-root {
      background-color: #f4f2f9;
      padding: 0;
    }
  }
}

#selected .Datepicker--DateLabel {
  margin-top: 0px !important;
}
.selectBoxA select {
  background: url(../../../Assets/Images/bottom-arrow-form-pz.png)
    no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 290px;
}
.Datepicker--button-prev {
  svg {
    margin-left: 5px;
    font-size: 14px;
  }
}
.Datepicker--button-next {
  svg {
    margin-left: 5px;
    font-size: 14px;
  }
}
@media (min-width: 1300px) {
  .Datepicker--Container {
    width: 1000px !important;
  }
}
