// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
#headerColor {
    background: rgba(226, 220, 242, 0.38);
    padding: 16px !important;
    span {
      margin: 0
    }
    .MuiCardHeader-content {
      span {
        font-family: 'Poppins' !important;
        font-size: 14px;
        margin-left: 10px;
        color: #21034F;
        font-weight: bolder;
        letter-spacing: 0.5px;
      }
    }
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select
  {
    min-height: 0px !important;
    height: 10px !important;

  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input
  {
    height: 10px !important;
  }

  .available-slots
  {
    margin-top: 1vw;
    height:20vh;
    width: 20vw;
    background-color: #F4F4F4;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8vw;
    border-radius: 5px;
  }
  // .MuiCardHeader-root
  // {
  //   background-color: #F4F2FA;
   
  // }

// .MuiCardHeader-root
// {
//   padding: auto !important;
// }
