// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.

.rating_count{
    vertical-align: super;
    padding-left: 20px;
}
.rating-review-card{
    padding-left: 0px;
    margin-bottom:1px;
      
    .rating-card-header{
      font-weight: 700;
      font-family: Poppins !important;
      color:#102E35;
      .MuiCardHeader-action{
        margin-right: -22px !important;
      }
      span {
        color: #102E35 !important;
       
      }
    }
    .rating-card-content.MuiCardContent-root{
      padding: 0 6% 11% 6% !important;
    }
    .rating-card-content{
      color:#102E35;
      select:focus{
        outline:none;
      }
      .select-parking-div{
        display: inline;
        .select-parking-input{
          width: 90%;
          margin-top: 1%;
          padding-left: 2%;
          outline: transparent;
          border:none;
          background-color: #E6E6EF;
          height: 35px;
          border-radius: 90px;
          color: #102E35 !important;
          background: 'url(../../../Assets/Images/bottom-arrow-form-pz.png)';
          border-right: 10px solid #e6e6ef;
          font-size: 12px;
        }
      }
      .ratings_container{
        display: flex;
        justify-content: space-between;
        .ratings {
          padding: 15px 0px 15px 0px;
          .rating-h2{
            font-family: 'Poppins' !important;
            font-weight:bold;
            color:#102E35 !important;
          }
          p{
            font-size:12px;
            font-family: 'Poppins' !important;
            color:#102E35;
          }
        } 
        .ratings-component-div{
          padding: 15px 10% 15px 0px;
        } 
      }
      .progressbar_div{
        padding-top: 5px;
        border-bottom: 1.5px solid #DEDEDE;
        .progressbar_container{
          .star_rating{
            display: flex;
            .star_rating_icon{
              color: #F49D3E;
              font-size: 18px;
            }
          }
          .linear_progress{
            margin-left: -20px !important;
        }
        }
      }
      .review-error-div{
        text-align: center;
        margin: 25% 35% 30% 30%;
        
      }
      .comment_container{
        overflow: hidden;
        .comment{
          padding: 4% 0px 4% 0px;
          border-bottom: 1.5px solid #DEDEDE;
          .p-lastname{
            font-size: 12px;
            font-weight: bold;
            padding-bottom: 5px;
          }
          .p-lastupdateddate{
            font-weight: 'regular';
            font-size: 12px;
          }
          .comment-flexitext-grid{
            word-break: break-word;
            padding-top: 2.5%;
          }
          .each-rating-star{
            text-align: center;

          }
        }
      }
      .allreview-link-grid{
        margin-top: 4%;
        font-size: 12px;
      }
}


  // border-top: 1px solid;
}

.viewMore{
  color: #5310ee !important;
}


.MuiLinearProgress-colorSecondary {
    background-color: #F5F5F5 !important;
}
.MuiLinearProgress-barColorSecondary {
    background-color: #F49D3E !important;
}
.MuiLinearProgress-root{
    height: 20px !important;
    margin-bottom: 8px;
}
.MuiRating-root {
    color: #F49D3E !important;
    font-size: 1.25rem !important;
}





.percentage{
  font-weight: 'regular';
  font-size: 14px;
  padding-top: 0px !important;
}
