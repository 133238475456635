/* // Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. */
.rm-card {
  margin: 15px;
  border-radius: 10px;
  opacity: 1;
  border: 1px solid #70707029;
  box-shadow: 0px 3px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 763px;
}

.rm-content {
  text-align: center;
  top: 50%;
  left: 50%;
  position: absolute;
}

.no-role-text {
  text-align: center;
  letter-spacing: 0px;
  color: #102e35;
  opacity: 1;
}

.rm-img {
  width: 150px;
}

.rm-popup-modal {
  padding: 15px;
  width: 1000px;
  height: 700px;
}

.close-popup {
  float: right;
  cursor: default;
}

.rm-popup-content input {
  border: 1px solid #000;
  border-radius: 5px;
  margin-top: 5px;
  padding: 8px;
  margin-bottom: 5px;
}

.rm-popup-content textarea {
  border: 1px solid #000;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 8px;
}

.ps-func,
.um-func,
.rm-func,
.reports-func {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.roleMgmt-func {
  margin-top: 20px;
}

.rm-popup .MuiPaper-elevation8 {
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
