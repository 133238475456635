// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.

.makeStyles-drawer-247 {
  white-space: normal;
}
.MuiListItemText-inset {
  padding-left: 0px !important;
}
.MuiTypography-body1 {
  font-size: 0.8rem !important;
}
.sidebarIcon {
  width: 20px;
  margin: 0px 20px 0px 15px;
}
.nav-link {
  display: flex;
  align-items: center;
  padding: 17px 0 17px 5px;
  width: 100%;
}
.nav-link a {
  text-decoration: none;
  color: black;
}
.MuiButtonBase-root.active a {
  color: #01c0c8 !important;
}
.makeStyles-drawerOpen-171 .MuiSvgIcon-root {
  display: block;
}
.makeStyles-drawerClose-172 .MuiSvgIcon-root {
  display: none;
}

.left-panel {
  svg,
  img {
    width: 24px;
    margin: 0px 20px 0px 15px;
  }
  &.active {
    .nav-link.stroke {
      svg {
        path {
          stroke: #09aaa5;
        }
      }
    }
    .nav-link.fill {
      svg {
        path {
          fill: #09aaa5;
        }
      }
    }
  }
  .nav-link.fill {
    svg {
      margin: 0;
    }
  }
  &.active {
    .nav-link.stroke {
      div {
        span {
          font-weight: bold;
        }
      }
    }
  }
}

.left-panel {
  height: 100px;
  .addremove-icon {
    margin-right:auto;
  }
}

.makeStyles-drawerClose-12 {
  .MuiList-root {
    .left-panel {
      height: 57px;
      position: relative;
      transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }
    .left-panel {
      .nav-link.fill {
        display: flex;
        align-items: center;
        padding: 10px 0 17px 0px;
        width: 100%;
        transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      }
    }
  }
}

@media (min-width: 600px) {
  .makeStyles-drawerClose-12 {
    .MuiList-root {
      .left-panel {
        height: 73px;
      }
      .left-panel {
        .nav-link.fill {
          display: flex;
          align-items: center;
          padding: 17px 0 17px 8px;
          width: 100%;
          transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        }
      }
    }
  }
}

// .MuiButtonBase-root.active {
//   background: #f2f2f0;
// }
.MuiCollapse-container {
  background: #f2f2f0;
}
.jss12 {
  .left-panel {
    height: 75px !important;
  }
}
