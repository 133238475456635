// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
.info-container {
  display: flex;
  padding: 10px 0px 0px 0px;

  .status {
    b {
      color: rgba(33, 3, 79, 1);
    }
    p {
      color: rgba(16, 46, 53, 1);
    }
  }
}

.cardSizeSlot {
  height:92%;
  background-color:white;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto ;
  //padding:3% 0% 0% 3%;
  box-shadow: none !important;
  padding: 2%;
  align-items: center;

}
.item2 {
  justify-self: center;
  display: flex;
}
.labelSlot {
  font-size: 0.9vw;
  font-weight: regular;
  font-family: 'Poppins' !important;
  justify-self: center;
  color:#21034F

}
.totalSlot{
  font-weight: regular;
  justify-self: center;
  color:#102E35;
  font-family: 'Poppins' !important;
}
.occSlot{
  font-weight: regular;
  color:#F30626;
  justify-self: center;
  font-family: 'Poppins' !important;
}
.vacSlot{
  font-weight: regular;
  color:#67B561;
  justify-self: center;
  font-family: 'Poppins' !important;
}
.cardSize {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  margin-bottom: 1.5rem;
  align-items: center;
}
.cardSizeRes {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  align-items: center;
}
.card-size {
  height: 92%
}
.card-size-res {
  height: 90%
}
.card-size-ey {
  // height: 90%;
  // width:90%
}
.mt-3 {
  display: grid;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}
.dashCardvalue{
 font-size: 1.5vw !important;
 font-weight: regular;
 font-family: 'Poppins' !important;
 color: #102E35 !important;
}
.dashCardLabel{
  font-size: 0.85vw;
  font-weight: regular;
  font-family: 'Poppins' !important;
  text-align: -webkit-left;
  color: #102E35 !important;
}
.dashCardLabelDev{
  font-size: 1vw;
  color: #A2A2A2;
  font-weight: regular;
  font-family: 'Poppins' !important; 
}
.imgCard {  
  width:100%;
  align-self: center;
}
.imgCardDevRes {  
  width:4vw;
  justify-self: center;
  
}
.imgCardDevEy {
  width:3vw;
  justify-self: center;
}
.imgArrowRes {
  width: 2.5vw;
  margin-bottom: 0.5vw;
  justify-self: center;
}
.clientCount {
  display: grid;
  align-content: center;
  width: 7.25vw;
  background-color:#00b7ff47;
  height: 3.25vw;
  margin-bottom: 0.5vw;
  justify-self: center;
  font-size: 26px;
  justify-content: center;
  margin-right: 0.5vw; 
}
.pzCount {
  display: grid;
  align-content: center;
  width: 5svw;
  background-color:#68ead991;
  height: 3.25vw;
  margin-bottom: 0.5vw;
  justify-self: center;
  font-size: 1.5vw;
  justify-content: center;
  margin-right: 0.7vw; 
  border-radius: 10px;
}
.bookingCount {
    display: grid;
    align-content: center;
    width: 5vw;
    background-color: rgba(104, 234, 217, 0.568627451);
    height: 3.25vw;
    justify-self: center;
    font-size: 1.5vw;
    justify-content: center;
    margin-right: 0.7vw;
    border-radius: 10px;
}

.imgCardDev {  
  width:100%;
  align-self: center;
}
.liveTick{
margin-left :5px;
padding-top:5px;
}
.devLabel{
font-size: 0.875vw;
font-weight: regular;
color : rgb(0, 183, 255);
font-family: 'Poppins' !important;

}
.devStatus{
  font-size: 1.5vw;
  font-weight: regular;
  color : #67B561;
  font-family: 'Poppins' !important;
}
.devStatusRes{
  font-size: 1.25vw;
  font-weight: regular;
  color : #67B561;
  font-family: 'Poppins' !important;
}
.sourceValue{
  font-size: 1vw;
  font-weight: regular;
  color : rgb(0, 183, 255);
  font-family: 'Poppins' !important;
  font-weight: bold;
  
  }
  .pzValue{
    font-size: 1vw;
    font-weight: regular;
    color : #67B561;
    font-family: 'Poppins' !important;
    font-weight: bold;
    
    }
  .sourceLabel{
    font-size:0.8vw;
    font-weight: regular;
    font-family: 'Poppins' !important;
  }
.devStatusOff{
  font-size: 1.3vw;
  font-weight: regular;
  color : grey;
  font-family: 'Poppins' !important;
}
.card-custom {
  border: 1px solid black;
  background-color: lavender;
  border-radius: 5px;
  padding: 15px;
}
.MuiIconButton-colorPrimary {
  color: #01c0cb !important;
}
.all-view-button {
  background-color: #03A9F3;
  color:white
}
.card-cont {
  position: relative;
  margin-bottom: 15px;

}
.card-cont-ey {
  position: relative;
  margin-bottom: 5%;

}
.cust-card {
  background-color:#F1F1F5 !important;
  height:91%;
  min-height: 140px;
  margin: 0 1.5% 1.5% 1.5%;
  box-shadow: none !important;
}
.cust-card-res {
  background-color:#F1F1F5 !important;
  height:95%;
  min-height: 140px;
  margin: 0 2.5% 1.5% 2.5%;
  box-shadow: none !important;
}
.cust-card-ey {
  background-color:#F1F1F5 !important;
  // height:90%;
  // min-height: 140px;
  margin: 0.5vw 1vw 1vw 1vw;
  box-shadow: none !important;
}

.cust-card.MuiPaper-elevation1 {
  box-shadow: 5px 0px 13px 8px #f3e5e529 inset !important;
}
.d-middle img {
  vertical-align: middle;
  padding-right: 10px;

  &:nth-child(2) {
    width: 80px;
    padding-left: 10px;
  }
}
.status {
  padding-right: 30%;
}
.status b {
  font-size: 110%;
  font-weight: bolder;
  font-family: 'Poppins' !important;
}
.status p {
  font-size: 1.5rem;
  font-weight: 450;
  font-family: 'Poppins' !important;
}
.card-footer {
  // padding: 15px 0px 0px 15px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.legend_last {
  margin: auto;
  display: flex;
  font-size: 8px;
  font-family: 'Poppins' !important;
}
.legend_last img {
  padding-right: 3px;
  width: 20px;
  
}
.legend_last span {
  margin-top: 3px;
}
.legend {
  font-family: 'Poppins' !important;
  font-size: 10px;
  color:#102E35;
  margin: auto;
  display: flex;  
  padding-right: 20px;
  
  span {
    margin-top: 3px;
    
  }
  img {
    padding-right: 3px;
    width: 20px;
  }
}
.legend-cont {
  display: flex;
  margin-left: 1em;
}
img.verified-image {
  
  width: 42%;
  height: 85%;
}
.view-container {
  max-height: 37em;
  overflow-y: hidden;
}
.MuiCardHeader-root {
  padding: 23px 15px 5px 25px !important;
}

.MuiAvatar-root {
  width: auto !important;
  height: auto !important;
}

.MuiButton-root {
  text-transform: none !important;
}

.MuiButton-containedPrimary {
  border-radius: 20px !important;
  background-color: #02a6dc !important;
  font-size: 11px !important;
  font-family: 'Poppins' !important;
}

tr.MuiTableRow-root.MuiTableRow-head {
  background-color: rgba(226, 220, 242, 0.38);

  th {
    color: #1f0350;
  }
}

.rainbow {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #f22),
    color-stop(0.15, #f2f),
    color-stop(0.3, #22f),
    color-stop(0.45, #2ff),
    color-stop(0.6, #2f2),
    color-stop(0.75, #2f2),
    color-stop(0.9, #ff2),
    color-stop(1, #f22)
  );
  background-image: gradient(
    linear,
    left top,
    right top,
    color-stop(0, #f22),
    color-stop(0.15, #f2f),
    color-stop(0.3, #22f),
    color-stop(0.45, #2ff),
    color-stop(0.6, #2f2),
    color-stop(0.75, #2f2),
    color-stop(0.9, #ff2),
    color-stop(1, #f22)
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
a.location_link {
  color: white;
  text-decoration: none;
  font-size: 10px;
  font-family: 'Poppins' !important;
}
.MuiCardHeader-root.card_head {
  flex-direction: row-reverse;
}
.card_head {
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
}
.card_head p:nth-child(3) {
  margin-left: auto;
  margin-top: -15px;
}
.card_head p:nth-child(1) {
  margin-right: 8px;
  font-weight: bold;
}
.editMenu {
  font-size: 15px !important;
  margin: -7px -3px -6px -4px !important;
  font-family: 'Poppins' !important;
}
// .MuiPopover-paper {
//   box-shadow: 1px 0.5px 1px 0.5px !important;
// }
.psoHeader {
  .MuiCardHeader-content {
    span {
      padding-bottom: 10px;
      font-weight: 700; font-family: 'Poppins' !important; 
      color: #21034F !important;
    }
  }
}
// .viewBoxMap{
//   width: 105% !important;
//   height:100vh ;
//   margin-left: -15px;
//   .cust-card :nth-child(odd) {
//     margin-left: 15px;

//   }
// }
.card-cont {
  .MuiCardContent-root {
    padding: 0px !important;
  }
}
.viewBoxMap {
  width: 100% !important;
height:84vh !important ;
 
}
.viewBoxMapEy {
  width: 100% !important;
height:84vh !important ;
 
}
.cust-card {
  .MuiCardContent-root {
    padding: 0.5% !important;
  }
}
.mapAvtr {
  .card_head {
    margin-left: 0px;
  }
}

.pso-filter {
  .selectBoxA {
    background: url(../../../Assets/Images/bottom-arrow-form-pz.png)
      no-repeat right #ddd;
    -webkit-appearance: none;
    background-position-x: 70px;
  }
}

.selectBoxA {
  .MuiCardContent-root {
    padding: 16px !important;
    background-color: rgba(244, 242, 250, 0.5);
  }
}

#pso-card-footer button {
  width: 175px;
}

.psoHeader {
  padding: 2% 2% 1.5% 3% !important;

}
.psoHeaderEY {
  padding: 2vw 0 0vw 2vw !important;
  background-color: white !important;

}

.d-middle {
  padding-left: 15px !important;
  font-weight: bold !important;
}

.dashboard {
  #pso-card-footer {
    padding-left: 2.5%;
  }
}
.dashboard {
  .filter-box {
    text-align: left;
    width: 145px !important;
    list-style: none;
    background-color: #fff;
    // position: absolute;
    top: 40px;
    z-index: 999;
    color: #000;
    font-size: 10px;
    padding-bottom: 5px;
    border: 1px #efefef solid;
    right: 0px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
    font-family: 'Poppins' !important;

    li {
      margin: 5px 0px 5px 0px;

      label {
        padding-top: 12px;
        color: #21034F !important;
      }
    }
    button {
      padding-left: 0px;
      background: transparent !important;
      padding: 5px 70px 0px 10px;
      width: auto;
      border: none;
      box-shadow: none;
      span {
        color: #102e35;
        font-size: 10px;
        justify-content: left;
        font-family: 'Poppins' !important;
      }
      .MuiSvgIcon-root {
        margin: 0px 5px 0px 5px;
      }
    }
    .MuiButton-contained:hover {
      box-shadow: none !important;
    }
  }
}
.parent {
  .cust-card {
    .card_head {
      position: relative;
      p:nth-child(3) {
        margin-left: 0 !important;
        margin-top: 0 !important;
      }
      .filter-box{
        position: absolute;
        right: 0px !important;
        top: 20px !important;
      }
      .dottedBtn {
        button {
          position: absolute;
          right: -9px;
          top: -20px;
        }
      }
    }
  }
}