/* // Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
 */
.App {
  text-align: center;
  
}
​ .App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
​ .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
​ .App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
​
/* UI Fixes */
a.link {
  color: #03a9f3;
}
.viewBoxMap table .MuiTableCell-root,
.react-calendar,
.react-calendar button,
.listview__wrapper table .MuiTableCell-root {
  font-family: 'Poppins' !important;
}
.viewBoxMap table th.MuiTableCell-root {
  font-size: 12px;
}
.viewBoxMap table th.MuiTableCell-root:first-child {
  padding-left: 58px;
}
.listview__wrapper table th.MuiTableCell-root:first-child {
  padding-left: 32px;
}
​ .viewBoxMap table td.MuiTableCell-root:first-child {
  position: relative;
  left: 15px;
}
.listview__wrapper table td.MuiTableCell-root:first-child {
  position: relative;
  left: 20px;
}
.viewBoxMap table td.MuiTableCell-root:last-child {
  padding-right: 44px;
  text-align: right !important;
  position: relative;
  right: 15px;
}
.listview__wrapper table td.MuiTableCell-root:last-child {
  padding-right: 44px;
  text-align: right !important;
  position: relative;
  right: 20px;
}
.parking__wrapper_card .parking__wrapper_card_card {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 20px !important;
}
.ParkingOverview-navbar__wrapper_nav_left_search input {
  background: white;
  color: black;
  font-family: 'Poppins' !important;
  padding: 10px 20px;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.ParkingOverview-navbar__wrapper_nav_left_search input::placeholder {
  color: #979797;
  font-family: 'Poppins' !important;
}
.ParkingOverview-navbar__wrapper_nav_left_searchicon {
  top: 8px;
}
.breadcrumbs__wrapper {
  font-size: 10px;
  font-family: 'Poppins' !important;
  margin-top: 17px;
  display: flex;
  align-items: center;
}
.breadcrumbs__wrapper a {
  color: #102e35;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-family: 'Poppins' !important;
  font-size: 15px;
}
.breadcrumbs__wrapper p {
  color: #102e35;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-family: 'Poppins' !important;
  font-size: 15px;

}
.parking__wrapper_card_card_box_data_status {
  margin-bottom: 10px;
  display: block;
}
.parking__wrapper_card_card_box {
  padding: 20px !important;
}
.parking__wrapper_card_card_box_data_value {
  color: #102e35bd;
}
.parking__wrapper_card_card_box .draft-icon p,
.View_button {
  color: #03a9f3;
  text-decoration: underline;
}
.parking__wrapper_card_card_box .draft-icon img {
  margin: -5px 22px 5px;
}
.listview__wrapper {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 10px;
}
.ParkingOverview-navbar__wrapper_button .filter-icon .filter-box {
  box-shadow: 0px 3px 6px #00000029;
}
.ParkingOverview-navbar__wrapper_button .filter-icon .filter-box li {
  margin: 0 15px;
  text-align: left;
}
.ParkingOverview-navbar__wrapper_button
  .filter-icon
  .filter-box
  li:nth-child(5) {
  /* border-bottom: 1px solid #707070; */
  padding-bottom: 5px;
}
.ParkingOverview-navbar__wrapper_button
  .filter-icon
  .filter-box
  li:nth-child(1) {
  padding-top: 5px;
}
.ParkingOverview-navbar__wrapper_button
  .filter-icon
  .filter-box
  button {
  margin-top: 10px;
  width: 80%;
}
.ParkingOverview-navbar__wrapper_button
  .filter-icon
  .filter-box
  li
  .PrivateSwitchBase-root-17 {
  padding: 9px 9px 9px 0;
}
.listview__wrapper {
  margin: 15px 20px;
  width: auto;
}
.ParkingOverview-navbar__wrapper_nav {
  padding: 9px 20px 5px;
}
.mapContainer {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  margin-top: 15px !important;
}
.mapContainer .card_head {
  margin-top: 15px;
}
.mapContainer .MuiCardContent-root {
  padding-top: 0;
}
.mapContainer .info_map_container {
  font-size: 11px;
}
.mapContainer .info_map_value {
  color: #102e35bd;
}
​ .DetailParking__wrapper_basicdetails_right_moredetails,
.DetailParking__wrapper_basicdetails_right_address {
  background: #f1f1f5;
}
.DetailParking__wrapper_basicdetails_right_moredetails_box ul {
  margin: 0;
}
.DetailParking__wrapper_basicdetails_left_carousel-box img {
  height: 352px;
}
.DetailParking__wrapper_basicdetails_left_carousel-box
  .carousel-status {
  display: none;
}
.DetailParking__wrapper_basicdetails_right_address,
.DetailParking__wrapper_basicdetails_right_moredetails {
  margin: 15px 0;
}
.DetailParking__wrapper_basicdetails_right {
  margin-left: 0;
}
.DetailParking__wrapper_basicdetails_right_moredetails_box ul li {
  padding: 5px 10px;
}
​
  .DetailParking__wrapper_basicdetails_left_carousel-box
  .carousel
  .control-dots
  .dot {
  opacity: 1;
  background: #ffffff;
  margin: 0 5px;
}
.DetailParking__wrapper_basicdetails_left_carousel-box
  .carousel
  .control-dots
  .dot.selected,
.DetailParking__wrapper_basicdetails_left_carousel-box
  .carousel
  .control-dots
  .dot:hover {
  background: #01c0c8;
}
.parkingSpace__wrapper {
  padding: 5px 10px 0;
}
.details_card .MuiCardHeader-content {
  padding: 15px 20px;
}
.live_now {
  right: 57em;
}
.slotcount thead.MuiTableHead-root,
.DetailParking thead.MuiTableHead-root,
.slotcount thead tr.MuiTableRow-root.MuiTableRow-head {
  background: #E2DCF2 !important;
  color: black !important;
}
.slotcount th.MuiTableCell-alignLeft,
.DetailParking th.MuiTableCell-alignLeft,
.DetailParking th.MuiTableCell-alignRight {
  /* color: #ffffff !important; */
  font-family: 'Poppins' !important;
  border: 0 none;
}
​ .slotcount thead.MuiTableHead-root th:first-child,
.DetailParking thead.MuiTableHead-root th:first-child {
  /* -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; */
}
.slotcount thead.MuiTableHead-root th:last-child,
.DetailParking thead.MuiTableHead-root th:last-child {
  /* -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
}
.addparking__wrapper
  .stepper
  .MuiStepper-alternativeLabel
  .MuiStepIcon-root.MuiStepIcon-active {
  box-shadow: 0px 3px 6px #0000001f;
}
.makeStyles-drawerOpen-11,
.makeStyles-drawerClose-12 {
  box-shadow: 6px 6px 6px #0000000f;
}
.addparking__wrapper,
.addparking__wrapper .MuiPaper-root {
  background: #efefef;
}
.contactdetails__wrapper .contactdetails__wrapper__container,
.addressdetails__wrapper .addressdetails__wrapper__container,
.AddParkingStepThree__wrapper__container {
  background: #ffffff;
}
.addressdetails__wrapper .addressdetails__wrapper__container {
  padding: 25px 40px 15px;
}
.addressdetails__wrapper__container .show__address {
  margin: 0 auto;
}
.addressdetails__wrapper__heading,
.contactdetails__wrapper__heading,
.tariffdetails__wrapper__heading,
.AddParkingStepThree__wrapper__heading {
  background: #e2dcf2;
  -webkit-border-top-left-radius: 8px;
  -webkit-border-top-right-radius: 8px;
  -moz-border-radius-topleft: 8px;
  -moz-border-radius-topright: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 84px;
  align-items: center;
}
.tariffdetails__wrapper__container__leftside,
.tariffdetails__wrapper__container__rightside {
  border-radius: 8px;
}
.addressdetails__wrapper,
.contactdetails__wrapper,
.tariffdetails__wrapper__container__leftside,
.tariffdetails__wrapper__container__rightside {
  box-shadow: 0px 3px 6px #00000029;
}
.addressdetails__wrapper__container > div {
  margin: 20px 0 !important;
  border: 1px solid #adc4c9 !important;
  border-radius: 8px;
}
.addressdetails__wrapper__container,
.contactdetails__wrapper__container,
.tariffdetails__wrapper__container__leftside,
.tariffdetails__wrapper__container__rightside,
.AddParkingStepThree__wrapper__container {
  -webkit-border-bottom-right-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-radius-bottomright: 8px;
  -moz-border-radius-bottomleft: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.addressdetails__wrapper,
.contactdetails__wrapper {
  border-radius: 8px;
  margin: 20px;
  width: auto;
}
.addressdetails__wrapper {
  margin-top: 0;
}
.contactdetails__wrapper__container__leftside input,
.contactdetails__wrapper__container__leftside
  .MuiInputBase-inputMultiline,
.addparking .drag__and_drop,
.button__wrapper,
.addressdetails__wrapper__container .show__address,
.tariffdetails__wrapper__container__leftside_twowheeler_numoflots,
.tariffdetails__wrapper__container__leftside_twowheeler_tarrif select,
.tariffdetails__wrapper__container__leftside_twowheeler_tarrif input {
  border: 1px solid #93a1a4;
  border-radius: 8px;
}
p.num_prefix {
  border-right: 1px solid #93a1a4;
}
.addressdetails__wrapper__container .show__address {
  margin: 0 !important;
  width: 100%;
}
.addressdetails__wrapper__container .autocomplete input {
  margin-left: 40px;
}
.contactdetails__wrapper__container__rightside,
.contactdetails__wrapper__container__leftside {
  margin: 30px 40px;
}
.tariffdetails__wrapper__container__leftside,
.tariffdetails__wrapper__container__rightside {
  margin: 0;
}
.tariffdetails__wrapper {
  margin: 20px;
}
.tariffdetails__wrapper__container__leftside {
  margin-right: 10px;
}
.tariffdetails__wrapper__container__rightside {
  margin-left: 10px;
}
.tariffdetails__wrapper__container__leftside .btn {
  padding: 0;
  width: 27px;
  height: 27px;
  position: relative;
  top: 9px;
}
.tariffdetails__wrapper__container__leftside .btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ParkingOverview-navbar__wrapper_button .filter-icon .filter-box {
  width: 185px !important;
}
.PrivateSwitchBase-root-26 {
  padding: 9px 9px 0;
}
.details_card .MuiCardHeader-root {
  width: 100%;
}
.MuiTab-labelIcon {
  width: 33.33% !important;
}
.button__wrapper {
  width: calc((100% - 15px) / 2);
}
.button__wrapper:last-child {
  margin-right: 0;
}
.tariffdetails__wrapper__container__leftside_content
  .MuiTabs-scroller
  .MuiTabs-flexContainer {
  display: block;
}
.addparking__wrapper_bottombuttons .next Button {
  box-shadow: 4px 4px 6px #00000024;
}
.AddParkingStepThree__wrapper {
  width: auto;
  margin: 0 20px 20px;
  border-radius: 8px;
}
.ParkingFacilities__wrapper_heading {
  color: #21034f;
}
.makeStyles-drawerClose-12 .MuiList-root .left-panel {
  height: 67px;
}
.terms .Cancel {
  margin: 0 10px 0 0 !important;
}
.vertical-col-2,
.address {
  display: -webkit-box;
  display: block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
tr.draft td {
  color: #a2a2a2 !important;
}
.popUp-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  top: 0px;
  left: 0px;
  z-index: 9999;
}
.app-footer .popUp {
  position: fixed;
  width: 80%;
  margin: 0 auto;
  z-index: 9999;
  background: #fff;
  padding: 20px;
  border: 1px solid;
  border-radius: 10px;
  top: 50%;
  color: black;
  left: 50%;
  padding-top: 0px !important;
  transform: translate(-50%, -50%);
}

​ .add__parking_module .terms .terms__buttons_submit {
  padding: 30px 50px 0;
  display: block;
  text-align: center;
}
.gm-svpc {
  display: none;
}
.gm-fullscreen-control {
  display: none;
}
@media (min-width: 1356px) {
  #primary-search-account-menu .MuiPaper-elevation8 {
    width: 182px !important;
  }
}

.footer-bottom {
  width: 100%;
  /* position: fixed !important; */
 
}

.MuiPickersDay-daySelected:hover {
  background-color: #5767be !important;
  color: #fff !important;
}