// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
.ForgotPassword {
    width: 100%;
    background: rgba(1, 192, 203, 1);
    min-height: 100vh;
  
    &__wrapper {
      width: 500px;
      margin: 0 auto;
     &_box {
        position: absolute;
        top: 40%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: #fff;
        border-radius: 1em;
        .label_text {
          font-size: 12px;
        }
        .error {
          color: red;
          opacity: 0.5;
          font-weight: lighter;
  
          button {
            background-color: transparent !important;
            border: none;
            float: right;
            font-size: 14px;
            margin-top: 5px;
            color: red;
            cursor: pointer;
            font-weight: lighter;
          }
        }
  
        &_header {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid;
          margin-bottom: 25px;
  
          img {
            width: 85px;
          }
  
          &_text {
            font-weight: 700;
            background-color: rgba(226,220,242,.38);
            padding: 1rem;
          }
        }
        .password-box {
          position: relative;
          .passwordIcon {
            border-left: 2px solid;
            position: absolute;
            top: 2px;
            right: 11px;
            height: 46px;
            padding-left: 10px;
  
            img {
              padding-top: 50%;
              width: 30px;
            }
          }
        }
      }
    }
    label {
      display: block;
      margin: 10px auto;
      text-align: initial;
      color: #000;
    }
    input {
      display: block;
      width: 100%;
      padding: 12px;
      margin-bottom: 15px;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid #f2f2f0;
      outline: none;
      font: inherit;
    }
  }
  
  @media (min-width: 1400px) {
    .Login {
      height: 100vh;
    }
  }
  