// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
button.MuiButtonBase-root.MuiIconButton-root.editButton {
  background-color: #e46a76 !important;
  color: white !important;
  border-radius: 20px !important;
  width: 90px !important;
  padding: 4px !important;
  font-size: 15px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.topPad {
  margin-top: 8px !important;
  .MuiLink-underlineHover:hover {
    text-decoration: none !important;
  }
}

#headerColor {
  background: rgba(226, 220, 242, 0.38);
  padding: 16px !important;
  span {
    margin: 0
  }
  .MuiCardHeader-content {
    span {
      font-family: 'Poppins' !important;
      font-size: 14px;
      margin-left: 10px;
      color: #21034F;
      font-weight: bolder;
      letter-spacing: 0.5px;
    }
  }
}

.MuiContainer-root {
  width: 90%;
  padding:4px 1%;
  margin:2%;
}
.MuiDialogContent-root {
  overflow-y: hidden !important;
}

.MuiAvatar-root.MuiAvatar-square.picture {
  width: 175px !important;
  height: 175px !important;
  padding: 15px;
  margin:0;
  margin-left: 10%;
}

.picture > .MuiAvatar-img {
  border-radius: 5px;
  border: 0.75px solid #102e35;
}

.profileName {
 // padding-top: 15px;
  padding-bottom: 1.5%;
  font-size: 24px;
  color: rgba(16, 46, 53, 1);
  font-weight: 300;
}

.info {
  line-height: 25px;
  display: flex;
}

.left {
  margin-right: 1em;
}

.btnContainer {
  margin-left: 2.5em !important;
  button {
    margin: auto;
    display: block;
    width: 141px;
    height: 48px;
  }

  margin-bottom: 3em;
  margin-top: 2em;
  float: left;
  margin-left: 5em;
}

.pwdLink {
  padding-top: 1.5%;
  cursor:'pointer';
  a {
    color: #03a9f3 !important;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
}

.inputBox,
.btnCustom {
  line-height: 25px;
}

.inputBox {
  border-radius: 3px;
  border: 1px solid black;
  padding-right: 100px;
  padding-left: 10px;
}

.btnCustom {
  background-color: transparent;
  position: absolute;
  right: 24px;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  padding-top: 3px;
}
input:focus{
  outline: none !important;
}

.current,
.new {
  padding-bottom: 2em;
}
.pwd-cancel{
  font-family: 'Poppins' !important;
  cursor: pointer;
  background-color: #707070 !important;
  border-radius: 90px !important;
  border: none !important;
  float: right;
  margin: auto !important;
  padding: 5px 15px 5px 15px !important;
  color: #ffffff !important;
  width: 120px;
  height: 30px;
  box-shadow: 4px 4px 6px #00000024;
  margin-bottom: 30px !important;
}

.pwd-submit {
  font-family: 'Poppins' !important;
  cursor: pointer;
  background-color: #03a9f3 !important;
  border-radius: 90px !important;
  border: none !important;
  float: right;
  margin: auto !important;
  padding: 5px 15px 5px 15px !important;
  color: #ffffff !important;
  width: 120px;
  height: 30px;
  box-shadow: 4px 4px 6px #00000024;
  margin-bottom: 30px !important;
}

.current p,
.new p,
.verify p {
  font-size: 14px;
  color: #21034f;
  font-weight: 900;
  padding-bottom: 3px;
  opacity: 1;
}

.strength {
  padding-top: 1em;
  letter-spacing: 0px;
  color: #102e35;
  opacity: 1;
  font-weight: 500;
}

.boxes,
.company,
.role {
  padding: 15px;
}

.boxes p {
  font-weight: 900;
}

.company,
.role {
  display: flex;
}

.company p:nth-child(1),
.role p:nth-child(1) {
  font-weight: 900;
  margin-right: 1em;
}
.info {
  .left {
    p {
      color: #102e35;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .right {
    p {
      color: #102e35;
      font-size: 14px;
    }
  }
}
#alert-dialog-title {
  margin-top: 5px;
  .MuiTypography-h6 {
    color: #21034F !important;
    opacity: 1;
    font-size: 20px !important;
  }
}
.MuiDialogActions-root {
  padding: 8px 24px !important;

}
@media (max-width: 1100px) and (min-width: 992px) {
  .MuiAvatar-root.MuiAvatar-square.picture {
    width: 175px !important;
  }
  .btnContainer {
    margin-left: 1.5rem !important;
    button {
      width: 130px;
    }
  }
}
@media (min-width: 1356px) {
  .MuiAvatar-root.MuiAvatar-square.picture {
    width: 75% !important;
    height: 225px !important;
    
  }
  .info {
    line-height: 35px;
    display: flex;
  }
}
.editButton {
  span:hover {
    text-decoration: none !important;
  }
}
