// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
.noPadBtn {
  padding: 12px 0px 12px 0px !important;
}
.noPadBtn:hover {
  background-color: transparent !important;
}
.sel_box {
  width: 25em;
  margin-right: 2em;
  padding-bottom: 5px;
  outline: transparent;
  padding-top: 5px;
  border-color: #eee;
  border-radius: 12px;
}
.select:focus{
  outline:'none'
}
.label_slots {
  margin-left:1%;
  font-weight: 900;
  color:#102E35;
  font-size: 14px;
}
.slot_value {
  color:#67B561;

}

.vehicle_type_image {
  width: 25px;
  margin-left: 1.5%;
  height: 2%;
}
.puHeader{
  font-size:14px;
  font-weight:bold;
  display:inline;
}
.puHeader.MuiCardHeader-root{
  padding: 5px 10px 5px 10px !important;
}

select{
  -webkit-appearance: auto;
}
.selectBoxA {
  // font-size: 12.88px;
  // border-radius: '90px';
  // background: #e6e6ef 0% 0% no-repeat padding-box;
  // border-radius: 20px;
  // width: '200px';
  // margin-left: '50px';
  // outline: 'transparent';
  // border-color: '#eee';
  // background-color: '#E6E6EF';
  // height: '554px';
  -webkit-appearance: auto;
  cursor: pointer;
  padding: 5px 10px 5px 10px !important;
  // .MuiCardContent-root {
  //   padding: 5px !important;
  // }
  
  // .card-footer {
  //   button {
  //     color: white !important;
  //     box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  //       0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  //       0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  //   }
  // }
}
.selectBoxB {
  // font-size: 12.88px;
  // background: #E6E6EF 0% 0% no-repeat padding-box;
  // border-radius: 90px;
  -webkit-appearance: auto;
  cursor: pointer;
  padding: 5px 10px 5px 10px !important;
  // .MuiCardContent-root {
  //   padding: 5px !important;
  // }
  
  // .card-footer {
  //   button {
  //     color: white !important;
  //     box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  //       0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  //       0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  //   }
  // }
}


#chart-filter {
  .selectBoxA {
    // background: url(../../../Assets/Images/bottom-arrow-form-pz.png)
    //   no-repeat right #ddd;
    // -webkit-appearance: none;
    // background-position-x: 170px;
  }
  .selectBoxB {
    // background: url(../../../Assets/Images/bottom-arrow-form-pz.png)
    //   no-repeat right #ddd;
    // -webkit-appearance: none;
    // background-position-x: 90px;
  }
}

.MuiCardHeader-root.puHeader {
  // padding-top: 17px !important;
  // padding-left: 10px !important;
  // padding-right: 0px !important;
  // padding-bottom: 0px !important;
}
.Datepicker--DateList--scrollable {
  flex-direction: row-reverse !important;
}
.Datepicker--DateDayItem .DateDayItem--disabled,
.Datepicker--DateDayItem.DateDayItem--selected {
  position: relative !important;
  left: 20px !important;
}
canvas{
  width: auto;
}